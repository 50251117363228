
@keyframes slideIn {
  0% {
    max-height: 0;
    margin-bottom: 0;
  }
  100% {
    max-height: 320px;
    margin-bottom: 20px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


// Loading Animation
@keyframes shimmer {
  0% {
    background-position: 150% 0;
  }
  100% {
    background-position: -50% 0;
  }
}

.spinAnimation {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


// React Transition Groups
.profile-enter,
.profile-appear {
  opacity: 0.01;
}

.profile-enter.profile-enter-active,
.profile-appear.profile-appear-active {
  opacity: 1;
  transition: opacity 0.5s;
}

.profile-leave {
  opacity: 1;
}

.profile-leave.profile-leave-active {
  opacity: 0.01;
  transition: opacity 0.5s;
}