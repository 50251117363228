@font-face {
  font-family: ProximaNovaRegular;
  src: url("/fonts/proximaNova/ProximaNovaRegular.woff") format("woff");
}

@font-face {
  font-family: ProximaNovaRegularItalic;
  src: url("/fonts/proximaNova/ProximaNovaRegularItalic.woff") format("woff");
}

@font-face {
  font-family: ProximaNovaCondensedBold;
  src: url("/fonts/proximaNova/ProximaNovaCondensedBold.woff") format("woff");
}

@font-face {
  font-family: ProximaNovaBold;
  src: url("/fonts/proximaNova/ProximaNovaBold.woff") format("woff");
}

@font-face {
  font-family: ProximaNovaSemibold;
  src: url("/fonts/proximaNova/ProximaNovaSemibold.woff") format("woff");
}

@font-face {
  font-family: ProximaNovaExtraCondensedExtrabold;
  src: url("/fonts/proximaNova/ProximaNovaExtraCondensedExtrabold.woff") format("woff");
}

@font-face {
  font-family: ProximaNovaBlackItalic;
  src: url("/fonts/proximaNova/ProximaNovaBlackItalic.woff") format("woff");
}

@font-face {
  font-family: MercuryDisplayBold;
  src: url("/fonts/mercuryDisplay/MercuryDisplayBold.woff") format("woff");
}

@font-face {
  font-family: BebasNeueRegular;
  src: url("/fonts/bebas/BebasNeue-Regular.otf") format("opentype");
}

@font-face {
  font-family: BebasNeueBold;
  src: url("/fonts/bebas/BebasNeue-Bold.otf") format("opentype");
}

@font-face {
  font-family: LatoLightItalic;
  src: url("/fonts/lato/Lato-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: LatoRegular;
  src: url("/fonts/lato/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: LatoBold;
  src: url("/fonts/lato/Lato-Black.ttf") format("truetype");
}



