html, body {
  font-family: ProximaNovaRegular, Arial, Helvitca, sans-serif;
  background-color: white;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

a:hover {
  text-decoration: none;
}

hr {
  border-top: thin solid #C6CCD2;
  margin: 24px 0;
}

*:focus {
  outline: none;
}

@media all and (min-width : 1025px) and (min-height : 640px) {
  .fixedBox {
    max-width: 426px;
    position: absolute;
    top: -112px;
    width: 29vw;
    z-index: 6;
  }

  .floatingBox {
    max-width: 426px;
    position: fixed;
    top:70px;
    width: 29vw;
    z-index: 6;
  }
}