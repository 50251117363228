.LogInOrSignUpIndependent-slick-dots {
  @extend .slick-slider-dots;
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin: 0px;
  bottom: 102px;

  @media (hover: hover) { 
    bottom: 70px;
  }

  @media (max-width: map-get($grid-breakpoints, sm)) {
    bottom: 102px;
  }
}

.LogInOrSignUpIndependent-slick-dots li.slick-active button {
  border-color: #16A9E1;
}