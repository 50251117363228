
// Generated with Icomoon. Use the Readme.txt and Selection.json in the /fonts/weatherIcons directory to edit


// Variables
//--------------
$icomoon-font-path: "/fonts/weatherIcons" !default;

$weather-clear-day: "\f00d";
$weather-wind: "\f012";
$weather-cloudy: "\f013";
$weather-fog: "\f014";
$weather-rain: "\f019";
$weather-sleet: "\f01a";
$weather-snow: "\f01b";
$weather-clear-night: "\f02e";
$weather-partly-cloudy-day: "\f031";
$weather-partly-cloudy-night: "\f086";



// Use Weather Font
//------------------
@font-face {
  font-family: 'weather-athlinks';
  src:  url('#{$icomoon-font-path}/weather-athlinks.eot?dlfj6a');
  src:  url('#{$icomoon-font-path}/weather-athlinks.eot?dlfj6a#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/weather-athlinks.ttf?dlfj6a') format('truetype'),
    url('#{$icomoon-font-path}/weather-athlinks.woff?dlfj6a') format('woff'),
    url('#{$icomoon-font-path}/weather-athlinks.svg?dlfj6a#weather-athlinks') format('svg');
  font-weight: normal;
  font-style: normal;
}



// Classes
//--------------
[class^="weather-"], [class*=" weather-"] {
  font-family: 'weather-athlinks' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.weather-clear-day {
  &:before {
    content: $weather-clear-day;
  }
}
.weather-wind {
  &:before {
    content: $weather-wind;
  }
}
.weather-cloudy {
  &:before {
    content: $weather-cloudy;
  }
}
.weather-fog {
  &:before {
    content: $weather-fog;
  }
}
.weather-rain {
  &:before {
    content: $weather-rain;
  }
}
.weather-sleet {
  &:before {
    content: $weather-sleet;
  }
}
.weather-snow {
  &:before {
    content: $weather-snow;
  }
}
.weather-clear-night {
  &:before {
    content: $weather-clear-night;
  }
}
.weather-partly-cloudy-day {
  &:before {
    content: $weather-partly-cloudy-day;
  }
}
.weather-partly-cloudy-night {
  &:before {
    content: $weather-partly-cloudy-night;
  }
}

