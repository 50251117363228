.global--header {
  @include z-depth(1);

  position: fixed;
  width: 100%;
  padding: 7px 0 0 0;
  background: white;
  z-index: 99999;

  &.sticky {
    .search-input, .location-input {
      display: inline-block !important;
      opacity: 1 !important;
    }
  }

  .main-logo {
    display: inline-block;

    .logo {
      margin-right: 10px;
    }

    .search-input {
      display: none;
      opacity: 0;
      transition: opacity .3s !important ;
    }

    .location-input {
      display: none;
      opacity: 0;
      transition: opacity .3s !important ;
      height: 18px;
      margin: 18px 0 10px 15px;
    }
  }

  .main-nav {
    @extend .d-none;
    display: inline-block;
    float: right;

    a {
      display: inline-block;
      line-height: 49px;
      margin-left: 15px;
      color: grey;
      font-size: 13px;
      letter-spacing: 1.1px;
    }
  }

  @media (max-width: map-get($grid-breakpoints, sm)) {
    .main-logo {
      display: flex;
    }
  }
}

