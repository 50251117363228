
.home-carousel {
  p {
    margin: 0;
  }
  .slick-track {
    padding-top: 40px;
    min-height: 460px;

    @media (min-width: 500px) {
      min-height: 430px;
    }
  }

  .slick-slide {
    position: relative;
    top: 0;
    width: 335px;
    padding: 0 5px;
    transition: top 0.3s;

    > * {
      box-shadow: 1px 1px 4px #d1d1d1;
      transition: all 0.3s;
    }

    .carousel-content {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      transition: opacity 0.2s, max-height 0.3s;
    }

    &.slick-center {
      top: -35px;
      transition: top 0.3s 0.3s;
    
      > a {
        box-shadow: 1px 4px 15px #d1d1d1;
      }
      
      .carousel-content {
        max-height: 200px;
        opacity: 1;
        transition: max-height 0.3s 0.3s, opacity 0.3s 0.4s;
      }
    }
  }
}