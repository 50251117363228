@media only screen and (max-width : 600px) {
  .rdrDateRangePickerWrapper {
    display: flex;
    flex-direction: column;
  }
  .rdrDefinedRangesWrapper {
    width: 100% !important;
  }
}

.rdrDateRangePickerWrapper {
  font-family: ProximaNova, sans-serif;
}

.rdrRangeLabel {
  font-family: ProximaNovaBold, sans-serif;
}

.rdrDateDisplayItem i:after {
  line-height: 13px;
  padding-left: 5px;
  padding-right: 5px;
}

.rdrMonthAndYearWrapper {
  padding-top: 0px !important;
}

.rdrStaticRanges {
  margin-top: 13px;
}

.rdrStaticRanges > div {
  padding-bottom: 20px !important;
}

.darkVersion input {
  color:white;
}

.rc-slider-disabled {
  background-color: #fff;
}

.rc-slider-track {
  height: 1px;
}

